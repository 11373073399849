.div-blur {
    -webkit-filter: blur(100px);
    -moz-filter: blur(100px);
    -o-filter: blur(100px);
    -ms-filter: blur(100px);
    filter: blur(100px);
    width: 40%;
    height: 100%;
    background-color: white;
    position: absolute;
    top: 0;
    left: -100px;
    z-index: -10; 
    opacity: 0.8;
  }