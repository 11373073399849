.bodyDiv::-webkit-scrollbar {
    background-color: #ffffff;
    width: 8px;
}

::-webkit-scrollbar-track {
}

.bodyDiv::-webkit-scrollbar-thumb {
    background: #782777;
    border-radius: 5px;
}

.bodyDiv::-webkit-scrollbar-thumb:hover {
    background: #a811a5; 
}