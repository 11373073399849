
.ant-steps-item-finish
> .ant-steps-item-container
> .ant-steps-item-tail::after {
background-color: #782777 !important;
}

.ant-steps-item-process .ant-steps-item-icon { background: #782777 !important; }

.ant-steps-item-process .ant-steps-item-icon {
background-color: #fff;
border-color: #782777 !important;
}

.ant-steps-item-process .ant-steps-item-icon {
background: #782777 !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
background-color: #fff !important;
border-color: #782777 !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #782777 !important;
}

.status-details {
  text-align: center;
  /* margin-left: 20px; */
}

.status-details h2 {
  padding-top: 50px;
  font-size: 18px;
}

.status-details p {
  margin: 1px;
}